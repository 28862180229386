import React from 'react'
import { Routes, Route } from "react-router-dom";
import AddMmv from '../pages/AddMmv';
import AllMmv from '../pages/AllMmv';
import Dashboard from '../pages/Dashboard';
import EditMmv from '../pages/EditMmv';
import Login from '../pages/Login';
import AuthRoute from './AuthRoute';
import CheckAuth from './CheckAuth';
import Extrapages from '../pages/Extrapages';


const AllRoutes = () => {
  return (
    <Routes>
      <Route element={<CheckAuth />}>

        <Route path="/" element={<Login />} />
      </Route>
      <Route element={<AuthRoute />}>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/allmmv" element={<AllMmv />} />
        <Route path="/addmmv" element={<AddMmv />} />
        <Route path="/editmmv" element={<EditMmv />} />
        <Route path="/extrapage" element={<Extrapages />} />
      </Route>
    </Routes>
  )
}

export default AllRoutes