import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import SideBar from '../common/SideBar'
import $, { data } from 'jquery'
import { GetData, GetDataWithToken, PostData, PostDataWithToken } from '../../api/apiHelper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loader from '../common/Loader'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from 'material-react-toastify'
import { MultiSelect } from "react-multi-select-component";



const AllMmv = () => {
  const [allmmv, setAllmmv] = useState([]);
  const [allSavedMmv, setAllSavedMmv] = useState([]);

  const [callApi, setCallApi] = useState(true)
  const [companyValue, setCompanyValue] = useState('')
  const [makeValue, setMakeValue] = useState('')
  const [modelValue, setModelValue] = useState('')
  const [variantValue, setVariantValue] = useState('')
  const [fuelValue, setFuelValue] = useState('')
  const [pages, setPages] = useState(1)
  const [filterPages, setFilterPages] = useState(1)
  const [filterData, setFilterData] = useState([])
  const [filterQuery, setFilterQuery] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [mmvUpdate, setMMvUpdate] = useState([])
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [editCompanyCode, setEditCompanyCode] = useState('')
  const [editCompanyName, setEditCompanyName] = useState('')
  const [mmvId, setMmvId] = useState('')
  const [loading, setLoading] = useState(false)
  const [vehicle_type, setVehicle_type] = useState('')
  // const [apiCompanyName, setApiCompanyName] = useState('')
  const [companyData, setCompanyData] = useState('')


  const [vehicleMake, setVehicleMake] = useState([])
  const [vehicleModal, setVehicleModal] = useState([])
  const [vehicleVariant, setVehiclVariant] = useState([])
  const [selectedColumn, setSelectedColumn] = useState([])

  const allColumn = [
    { label: "Make", value: 'Make' },
    { label: "Model", value: 'Model' },
    { label: "Variant", value: 'Variant' },
    { label: "Body Type", value: 'Body_Type' },
    { label: "Seating Capacity", value: 'Seating_Capacity' },
    { label: "Power", value: 'Power' },
    { label: "Cubic Capacity", value: 'Cubic_Capacity' },
    { label: "Grosss Vehicle Weight", value: 'Grosss_Vehicle_Weight' },
    { label: "Fuel Type", value: 'Fuel_Type' },
    { label: "No Of Wheels", value: 'No_Of_Wheels' },
    { label: "Abs", value: 'Abs' },
    { label: "Air Bags", value: 'Air_Bags' },
    { label: "Length", value: 'Length' },
    { label: "Ex Showroom Price", value: 'Ex_Showroom_Price' },
    { label: "Price Year", value: 'Price_Year' },
    { label: "Production", value: 'Production' },
    { label: "Manufacturing", value: 'Manufacturing' },
    { label: "Vehicle Type", value: 'Vehicle_Type' },

  ]


  const location = useLocation();
  const [mmvType, setMmvType] = useState(location.state?.mmvtype ? location.state?.mmvtype : 'id')
  const mmv = location.state?.mmvtype;


  useEffect(() => {
    let data = {

    }
    if (callApi == true) {
      setLoader(true)
      if (vehicle_type && makeValue && modelValue && variantValue && fuelValue && companyData != '') {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
          Variant: variantValue,
          Fuel_Type: fuelValue,
          companyValue: companyData,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue && modelValue && variantValue && fuelValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
          Variant: variantValue,
          Fuel_Type: fuelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      } else if (vehicle_type && makeValue && modelValue && variantValue && companyData != '') {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
          Variant: variantValue,
          companyValue: companyData,

        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue && modelValue && variantValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
          Variant: variantValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue && modelValue && fuelValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
          Fuel_Type: fuelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue && modelValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Model: modelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue && fuelValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
          Fuel_Type: fuelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type && makeValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Make: makeValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }

      else if (vehicle_type && fuelValue) {
        data = {
          Vehicle_Type: vehicle_type,
          Fuel_Type: fuelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (vehicle_type) {
        data = {
          Vehicle_Type: vehicle_type,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (companyData != '') {
        data = {
          companyValue: companyData,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else if (fuelValue) {
        data = {
          Fuel_Type: fuelValue,
        }
        PostData(`mmv/filter/${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      else {
        GetData(`mmv/get-mmv/${mmvType}?page=${pages}`, data).then((response) => {
          if (response.status == true) {
            setAllmmv(response.data)
            setAllSavedMmv(response.data)
            setFilterData([])
            setCallApi(false)
            setLoader(false)
          } else {
            setLoader(false)
          }
        })
      }
      setCallApi(false)
    }
  }, [callApi])


  // useEffect(() => {
  //   setTimeout(() => {
  //     $(document).ready(function () {
  //       $('#Allclients').DataTable();
  //     });
  //   }, 1200);
  // }, [allmmv, callApi])



  const filterVariant = (e) => {
    setFilterValue(e)
    setFilterPages(1)
    setFilterQuery('Variant')
    setVariantValue(e)
    setCallApi(true)
    if (e.length > 2) {
      // GetData(`mmv/filter/${1}?Variant=${e}`, '').then((response) => {
      //   if (response.status == true) {
      //     setAllmmv(response.data)
      //     setFilterData(response.data)
      //   }

      // })

    }
  }

  const filterFueltype = (e) => {
    setFilterValue(e)
    setFilterPages(1)
    setFilterQuery('Fuel_Type')
    setFuelValue(e)
    if (e.length > 3) {
      setCallApi(true)

      // GetData(`mmv/filter/${1}?Fuel_Type=${e}`, '').then((response) => {
      //   if (response.status == true) {
      //     setAllmmv(response.data)
      //     setFilterData(response.data)
      //   }

      // })

    }
  }

  const filterCompany = (e) => {
    // setCallApi(true)
    setMmvType(e)
    setCompanyValue(e)
    // setCallApi(true)

    // let filterData = allSavedMmv.filter(item => )
  }

  const resetFilter = () => {
    setPages(1)
    setVehicle_type('')
    setFilterValue('')
    setCallApi(true)
    setMmvType('id')
    setCompanyValue('')
    setFuelValue('')
    setMakeValue('')
    setModelValue('')
    setVariantValue('')
    setCompanyData('')
  }

  const nextPage = () => {
    setPages(pages + 1)
    setCallApi(true)
  }

  const previousPage = () => {
    setPages(pages - 1)
    setCallApi(true)

  }

  // const nextFilterPage = () => {
  //   setFilterPages(filterPages + 1)
  //   GetData(`mmv/filter/${filterPages + 1}?${filterQuery}=${filterValue}`, '').then((response) => {
  //     if (response.status == true) {
  //       setAllmmv(response.data)
  //       console.log("res", response)
  //       setFilterData(response.data)
  //     }

  //   })
  // }

  // const previousFilterPage = () => {
  //   setFilterPages(filterPages - 1)
  //   GetData(`mmv/filter/${filterPages - 1}?${filterQuery}=${filterValue}`, '').then((response) => {
  //     if (response.status == true) {
  //       setAllmmv(response.data)
  //       console.log("res", response)
  //       setFilterData(response.data)
  //     }

  //   })

  // }

  const handleValue = (value, id) => {
    setMmvId(id)
    setEditCompanyName(value)
    toggle()
  }

  const submitCode = () => {
    let data = {}
    setLoading(true)
    if (editCompanyName == "digit_vehicle_code") {
      data = {
        "digit_vehicle_code": editCompanyCode
      }
    } else if (editCompanyName == "royal") {
      data = {
        "royal": editCompanyCode
      }
    } else if (editCompanyName == "future") {
      data = {
        "future": editCompanyCode
      }
    } else if (editCompanyName == "reliance") {
      data = {
        "reliance": editCompanyCode
      }
    } else if (editCompanyName == "kotak") {
      data = {
        "kotak": editCompanyCode
      }
    } else if (editCompanyName == "shriram") {
      data = {
        "shriram": editCompanyCode
      }
    } else if (editCompanyName == "shriram") {
      data = {
        "shriram": editCompanyCode
      }
    }
    else if (editCompanyName == "shriram") {
      data = {
        "shriram": editCompanyCode
      }
    }
    else {
      data = {
        "HDFC": editCompanyCode
      }
    }

    PostDataWithToken(`mmv/edit/${mmvId}`, data).then((response) => {
      if (response.status == true) {
        toast.success(response.message);
        setCallApi(true)
        toggle()
        setLoading(false)
        // navigate('/allmmv')
      } else {
        toast.error(response.data.message);
      }
    })
  }

  const getMake = (e) => {
    setFilterValue(e)
    setFilterPages(1)
    setFilterQuery('Vehicle_Type')
    setVehicle_type(e)
    let data = {
      Vehicle_Type: e,
    }
    setCallApi(true)


    // GetData(`mmv/filter/${1}?Vehicle_Type=${e}`, '').then((response) => {
    //   if (response.status == true) {
    //     setAllmmv(response.data)
    //     setFilterData(response.data)
    //   }

    // })

    PostData(`motor/make`, data).then((response) => {
      if (response.status == true) {
        console.log("first", response.data)
        setVehicleMake(response.data)
      }
    })
  }

  const getModal = (e) => {
    setFilterValue(e)
    setFilterPages(1)
    setFilterQuery('Make')
    setMakeValue(e)
    let data = {
      Vehicle_Type: vehicle_type,
      make: e
    }
    setCallApi(true)


    // GetData(`mmv/filter/${1}?Make=${e}`, '').then((response) => {
    //   if (response.status == true) {
    //     setAllmmv(response.data)
    //     console.log("res", response)
    //     setFilterData(response.data)
    //   }

    // })

    PostData(`motor/model`, data).then((response) => {
      if (response.status == true) {
        console.log("first 2", response.data)
        setVehicleModal(response.data)
      }
    })
  }

  const getVariant = (e) => {
    setFilterValue(e)
    setFilterPages(1)
    setFilterQuery('Model')
    setModelValue(e)
    let data = {
      Vehicle_Type: vehicle_type,
      make: makeValue,
      model: e
    }
    setCallApi(true)


    // GetData(`mmv/filter/${1}?Model=${e}`, '').then((response) => {
    //   if (response.status == true) {
    //     setAllmmv(response.data)
    //     setFilterData(response.data)
    //   }

    // })

    PostData(`motor/variant`, data).then((response) => {
      if (response.status == true) {
        console.log("first 3", response.data)
        setVehiclVariant(response.data)
      }
    })
  }

  // console.log("mmvfd=====", mmvUpdate)

  const handleUpdateMMv = () => {
    const allData = JSON.stringify(mmvUpdate)
    const data = {
      field: companyValue,
      fieldArray: allData
    }
    setLoading(true)
    PostDataWithToken(`mmv/updateCodes`, data).then((response) => {
      if (response.status == true) {
        toast.success(response.message);
        setCallApi(true)
        setLoading(false)
        // navigate('/allmmv')
      } else {
        toast.error(response.data.message);
        setLoading(false)
      }
    })
  }

  const FilterbyValue = (e) => {
    if (e == 'Blank') {

      setCompanyData("null")
      setCallApi(true)
    } else {
      setCompanyData(e)
      setCallApi(true)
    }

  }


  return (
    <div id="layout-wrapper">
      <Header />
      <SideBar />

      <div className="main-content">
        {loader ? <Loader /> :
          <div className="page-content">
            <div className="container-fluid">
              {/*----- Page title and breadcrumb -----*/}
              <div className="row">
                <div className="col-12  d-flex justify-content-between align-items-center">
                  <div className="page-title-box">
                    <h4 className="mb-0">View All Entries</h4>
                    <div className="page-title-right mt-2">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <i className="fa-solid fa-house " />
                          <a href="dashboard.html">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">All Entries</li>
                      </ol>
                    </div>
                  </div>
                  <div className="backbtn">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate(-1)}
                      className="btn btn-primary w-sm waves-effect waves-light"
                    >
                      Back
                    </a>
                  </div>
                </div>
              </div>
              {/*----- All entries table -----*/}
              <div className="card mt-2">
                <div className="card-body">
                  <div className="row mx-0 px-0 align-items-center">
                    <div class="col-lg-3 col-12 ps-lg-0">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select class="form-select" id="clgen"
                            value={companyValue}
                            onChange={(e) => filterCompany(e.target.value)}
                          >
                            <option class="d-none" value={''} selected>Filter by company
                            </option>
                            <option value={"HDFC"} >HDFC</option>
                            <option value='Shriram' >Shriram</option>
                            <option value={"Kotak"}>Kotak</option>
                            <option value={"Reliance"}>Reliance</option>
                            <option value={"Future"}>Future</option>
                            <option value={"Royal"}>Royal</option>
                            <option value={"Vehicle_Code"}>Digit</option>
                            <option value={"Bajaj"}>Bajaj</option>
                            <option value={"Liberty"}>Liberty</option>
                            <option value={"Zuno"}>Zuno</option>
                            <option value={"Acko"}>Acko</option>
                            <option value={"Chola"}>Chola</option>
                            <option value={"ICICI"}>ICICI</option>
                            <option value={"IFFCO"}>IFFCO</option>
                            <option value={"Magma"}>Magma</option>
                            <option value={"National"}>National</option>
                            <option value={"Raheja"}>Raheja</option>
                            <option value={"SBI"}>SBI</option>
                            <option value={"Tata"}>Tata</option>
                            <option value={"NewIndia"}>NewIndia</option>
                            <option value={"Oriental"}>Oriental</option>
                            <option value={"United"}>United</option>
                            <option value={"Universal"}>Universal</option>
                            <option value={"Navi"}>Navi</option>
                            <option value={"id"}>All</option>
                          </select>

                          <label className="floatinglabel" for="clgen">Filter by company </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 ps-lg-0">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select className="form-select" value={vehicle_type} id="Vehi" onChange={e => getMake(e.target.value)}
                          >
                            <option className="d-none" value=''>
                              Select Vehicle type
                            </option>
                            <option value={"Pvt Car"}>Pvt Car</option>
                            <option value="MotorBike">MotorBike</option>
                            <option value="Scooter">Scooter</option>
                            <option value="Passenger Carrying">Passenger Carrying</option>
                            <option value="Goods Carrying">Goods Carrying</option>
                            <option value="Miscellaneous">Miscellaneous</option>
                            <option value="Trailer">Trailer</option>
                          </select>
                          <label htmlFor="Vehi" className="floatinglabel">
                            Vehicle type
                            <span className="mb-0 f-error">*</span>
                          </label>

                          {/* <p className="mb-0 f-error">{errors?.vehicle_type?.message}</p> */}

                        </div>
                        {/* {vehicletypeError == true && <span className="mb-0 f-error">This Field is required</span>} */}
                      </div>
                    </div>


                    <div className="col-lg-3 ">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select className="form-select" id="Make9"
                            value={makeValue}
                            onChange={e => getModal(e.target.value)}
                          >
                            <option className="d-none" value=''>
                              Select Make
                            </option>
                            {vehicleMake && vehicleMake.length > 0 && vehicleMake.map((item, index) => (

                              <option key={index} value={item.Make}>{item.Make}</option>
                            ))}
                          </select>
                          <label htmlFor="Make9" className="floatinglabel">
                            Make
                            <span className="mb-0 f-error">*</span>
                          </label>

                          {/* <p className="mb-0 f-error">{errors?.vehicle_make?.message}</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 ">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select className="form-select" id="modal"
                            value={modelValue}
                            onChange={e => getVariant(e.target.value)}
                          >
                            <option className="d-none" value=''>
                              Select Model
                            </option>
                            {vehicleModal && vehicleModal.length > 0 && vehicleModal.map((item, index) => (
                              <option key={index} value={item.Model}>{item.Model}</option>
                            ))}
                          </select>


                          <label htmlFor="modal" className="floatinglabel">
                            Model
                            <span className="mb-0 f-error">*</span>
                          </label>

                          {/* <p className="mb-0 f-error">{errors?.vehicle_model?.message}</p> */}

                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-12">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select className="form-select" id="variant"
                            value={variantValue}
                            onChange={(e) => filterVariant(e.target.value)}
                          >
                            <option className="d-none" value=''>
                              Select Variant
                            </option>
                            {vehicleVariant && vehicleVariant.length > 0 && vehicleVariant.map((item, index) => (
                              <option key={index} value={item.Variant}>{item.Variant}</option>
                            ))}
                          </select>

                          <label htmlFor="variant" className="floatinglabel">
                            Variant
                            <span className="mb-0 f-error">*</span>
                          </label>
                          {/* <p className="mb-0 f-error">{errors?.vehicle_variant?.message}</p> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-12 ps-lg-0">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={makeValue}
                          id="clname"
                          onChange={(e) => filterMake(e.target.value)}
                          placeholder="Filter by make"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          value={modelValue}
                          className="form-control"
                          id="clname"
                          onChange={(e) => filterModel(e.target.value)}
                          placeholder="Filter by model"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          value={variantValue}
                          className="form-control"
                          id="clname"
                          onChange={(e) => filterVariant(e.target.value)}
                          placeholder="Filter by variant"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-3 col-12 ps-lg-0">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <input
                            type="text"
                            value={fuelValue}
                            className="form-control"
                            onChange={(e) => filterFueltype(e.target.value)}
                            id="clname"
                            placeholder="Filter by fuel type"
                          />

                          <label htmlFor="clname" className="floatinglabel">
                            Fuel type
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-3 ps-lg-0">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <select className="form-select" value={fuelValue}
                            id="VehiFul"
                            onChange={(e) => filterFueltype(e.target.value)}

                          >
                            <option className="d-none" value=''>
                              Select Fuel type
                            </option>
                            <option selected>Select Fuel Typ</option>
                            <option value={"petrol"}>Petrol</option>
                            <option value={"diesel"}>Diesel</option>
                            <option value={"Battery"}>Battery</option>
                            <option value={"CNG"}>CNG</option>
                            <option value={"Electric"}>Electric</option>
                            <option value={"LPG"}>LPG</option>
                            <option value={"Petrol/CNG"}>Petrol/CNG</option>
                            <option value={"Petrol/LPG"}>Petrol/LPG</option>
                          </select>
                          <label htmlFor="Vehiful" className="floatinglabel">
                            Fuel type
                            <span className="mb-0 f-error">*</span>
                          </label>

                          {/* <p className="mb-0 f-error">{errors?.vehicle_type?.message}</p> */}

                        </div>
                        {/* {vehicletypeError == true && <span className="mb-0 f-error">This Field is required</span>} */}
                      </div>
                    </div>

                    <div class="col-lg-3 col-12 ps-lg-0">
                      <div className="position-relative mb-5">
                        <div className="form-floating">
                          <MultiSelect
                            options={allColumn}
                            value={selectedColumn}
                            onChange={setSelectedColumn}
                            labelledBy={"Select column to hide"}
                            isCreatable={true}
                          />

                          <label className="npm-lable" htmlFor="clgen">Filter by column</label>

                        </div>
                      </div>
                    </div>
                    {companyValue != '' &&
                      <div className="col-lg-3 col-12">
                        <div className="position-relative mb-5">
                          <div className="form-floating">
                            <select className="form-select" id="val"
                              value={companyData}
                              onChange={(e) => FilterbyValue(e.target.value)}
                            >
                              <option className="d-none" value=''>
                                Select value
                              </option>
                              <option value={0}>
                                0
                              </option>
                              <option value={null}>
                                Blank
                              </option>

                            </select>

                            <label htmlFor="val" className="floatinglabel">
                              Filter by value
                              {/* <span className="mb-0 f-error">*</span> */}
                            </label>
                            {/* <p className="mb-0 f-error">{errors?.vehicle_variant?.message}</p> */}
                          </div>
                        </div>
                      </div>}

                    {/* <div className="col-lg-3 col-12">
                   <div className="mb-3">
                     <input
                       type="text"
                       className="form-control"
                       id="clname"
                       placeholder="Enter Filter 6"
                     />
                   </div>
                 </div> */}
                    <div className="col-lg-3 col-12">
                      <div className="mb-3">
                        <span
                          onClick={resetFilter}
                          className="btn btn-primary w-sm waves-effect waves-light"
                        >
                          <i className="fa-solid fa-xmark text-danger fw-bold me-1" />
                          Reset Filter
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">

                    <table
                      id="Allclients"
                      className="table text-capitalize pt-5 table-striped table-bordered display nowrap mmvtable"
                      style={{ width: "100%" }}
                    >
                      <thead className='stickyTable'>
                        <tr>

                          {selectedColumn?.find(e => e.value == "Make") ? <></> : <th>Make</th>}
                          {selectedColumn?.find(e => e.value == "Model") ? <></> : <th>Model</th>}
                          {selectedColumn?.find(e => e.value == "Variant") ? <></> : <th>Variant</th>}
                          {selectedColumn?.find(e => e.value == "Body_Type") ? <></> : <th>Body Type</th>}
                          {selectedColumn?.find(e => e.value == "Seating_Capacity") ? <></> : <th>Seating Capacity</th>}
                          {selectedColumn?.find(e => e.value == "Power") ? <></> : <th>Power</th>}
                          {selectedColumn?.find(e => e.value == "Cubic_Capacity") ? <></> : <th>Cubic Capacity</th>}
                          {selectedColumn?.find(e => e.value == "Grosss_Vehicle_Weight") ? <></> : <th>Grosss Vehicle Weight</th>}
                          {selectedColumn?.find(e => e.value == "Fuel_Type") ? <></> : <th>Fuel Type</th>}
                          {selectedColumn?.find(e => e.value == "No_Of_Wheels") ? <></> : <th>No Of Wheels</th>}
                          {selectedColumn?.find(e => e.value == "Abs") ? <></> : <th>Abs</th>}
                          {selectedColumn?.find(e => e.value == "Air_Bags") ? <></> : <th>Air Bags</th>}
                          {selectedColumn?.find(e => e.value == "Length") ? <></> : <th>Length</th>}
                          {selectedColumn?.find(e => e.value == "Ex_Showroom_Price") ? <></> : <th>Ex Showroom Price</th>}
                          {selectedColumn?.find(e => e.value == "Price_Year") ? <></> : <th>Price Year</th>}
                          {selectedColumn?.find(e => e.value == "Production") ? <></> : <th>Production</th>}
                          {selectedColumn?.find(e => e.value == "Manufacturing") ? <></> : <th>Manufacturing</th>}
                          {selectedColumn?.find(e => e.value == "Vehicle_Type") ? <></> : <th>Vehicle Type</th>}
                          {companyValue == '' || companyValue == 'Vehicle_Code' ? <th>Digit Code</th> : <></>}
                          {companyValue == '' || companyValue == 'HDFC' ? <th>HDFC</th> : <></>}
                          {companyValue == '' || companyValue == 'Shriram' ? <th>Shriram</th> : <></>}
                          {companyValue == '' || companyValue == 'Kotak' ? <th>Kotak</th> : <></>}
                          {companyValue == '' || companyValue == 'Reliance' ? <th>Reliance</th> : <></>}
                          {companyValue == '' || companyValue == 'Future' ? <th>Future</th> : <></>}
                          {companyValue == '' || companyValue == 'Royal' ? <th>Royal</th> : <></>}
                          {companyValue == '' || companyValue == 'Bajaj' ? <th>Bajaj</th> : <></>}
                          {companyValue == '' || companyValue == 'Liberty' ? <th>Liberty</th> : <></>}
                          {companyValue == '' || companyValue == 'Zuno' ? <th>Zuno</th> : <></>}
                          {companyValue == '' || companyValue == 'Acko' ? <th>Acko</th> : <></>}
                          {companyValue == '' || companyValue == 'Chola' ? <th>Chola</th> : <></>}
                          {companyValue == '' || companyValue == 'ICICI' ? <th>ICICI</th> : <></>}
                          {companyValue == '' || companyValue == 'IFFCO' ? <th>IFFCO</th> : <></>}
                          {companyValue == '' || companyValue == 'Magma' ? <th>Magma</th> : <></>}
                          {companyValue == '' || companyValue == 'National' ? <th>National</th> : <></>}
                          {companyValue == '' || companyValue == 'Raheja' ? <th>Raheja</th> : <></>}
                          {companyValue == '' || companyValue == 'SBI' ? <th>SBI</th> : <></>}
                          {companyValue == '' || companyValue == 'Tata' ? <th>Tata</th> : <></>}
                          {companyValue == '' || companyValue == 'NewIndia' ? <th>NewIndia</th> : <></>}
                          {companyValue == '' || companyValue == 'Oriental' ? <th>Oriental</th> : <></>}
                          {companyValue == '' || companyValue == 'United' ? <th>United</th> : <></>}
                          {companyValue == '' || companyValue == 'Universal' ? <th>Universal</th> : <></>}
                          {companyValue == '' || companyValue == 'Navi' ? <th>Navi</th> : <></>}


                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allmmv && allmmv.length > 0 && allmmv.map((item, index) => (
                          <tr key={index}>
                            {selectedColumn?.find(e => e.value == "Make") ? <></> : <td>{item?.Make}</td>}
                            {selectedColumn?.find(e => e.value == "Model") ? <></> : <td>{item?.Model}</td>}
                            {selectedColumn?.find(e => e.value == "Variant") ? <></> : <td>{item?.Variant}</td>}
                            {selectedColumn?.find(e => e.value == "Body_Type") ? <></> : <td>{item?.Body_Type}</td>}
                            {selectedColumn?.find(e => e.value == "Seating_Capacity") ? <></> : <td>{item?.Seating_Capacity}</td>}
                            {selectedColumn?.find(e => e.value == "Power") ? <></> : <td>{item?.Power}</td>}
                            {selectedColumn?.find(e => e.value == "Cubic_Capacity") ? <></> : <td>{item?.Cubic_Capacity}</td>}
                            {selectedColumn?.find(e => e.value == "Grosss_Vehicle_Weight") ? <></> : <td>{item?.Grosss_Vehicle_Weight}</td>}
                            {selectedColumn?.find(e => e.value == "Fuel_Type") ? <></> : <td>{item?.Fuel_Type}</td>}
                            {selectedColumn?.find(e => e.value == "No_Of_Wheels") ? <></> : <td>{item?.No_Of_Wheels}</td>}
                            {selectedColumn?.find(e => e.value == "Abs") ? <></> : <td>{item?.Abs}</td>}
                            {selectedColumn?.find(e => e.value == "Air_Bags") ? <></> : <td>{item?.Air_Bags}</td>}
                            {selectedColumn?.find(e => e.value == "Length") ? <></> : <td>{item?.Length}</td>}
                            {selectedColumn?.find(e => e.value == "Ex_Showroom_Price") ? <></> : <td>{item?.Ex_Showroom_Price}</td>}
                            {selectedColumn?.find(e => e.value == "Price_Year") ? <></> : <td>{item?.Price_Year}</td>}
                            {selectedColumn?.find(e => e.value == "Production") ? <></> : <td>{item?.Production}</td>}
                            {selectedColumn?.find(e => e.value == "Manufacturing") ? <></> : <td>{item?.Manufacturing}</td>}
                            {selectedColumn?.find(e => e.value == "Vehicle_Type") ? <></> : <td>{item?.Vehicle_Type}</td>}
                            {companyValue == '' ? <td>{item?.Vehicle_Code ? item?.Vehicle_Code : "N/A"} </td> : companyValue == 'Vehicle_Code' ? <td>{item?.Vehicle_Code != 0 && item.Vehicle_Code != null ? item?.Vehicle_Code :
                              <input
                                type="text"
                                className="form-control"
                                id="digit_vehicle_code"
                                defaultValue={item?.Vehicle_Code}
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />}</td> : <></>}
                            {companyValue == '' ? <td>{item?.HDFC ? item?.HDFC : "N/A"}</td> : companyValue == 'HDFC' ? <td>{item?.HDFC != 0 && item?.HDFC != null ? item?.HDFC :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.HDFC}
                                id="HDFC"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Shriram ? item?.Shriram : 'N/a'} </td> : companyValue == 'Shriram' ? <td>{item?.Shriram != 0 && item?.Shriram != null ? item?.Shriram :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Shriram}
                                id="shriram"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Kotak ? item?.Kotak : 'N/A'}</td> : companyValue == 'Kotak' ? <td>{item?.Kotak != 0 && item?.Kotak != null ? item?.Kotak :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Kotak}
                                id="kotak"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Reliance ? item?.Reliance : "N/A"}</td> : companyValue == 'Reliance' ? <td>{item?.Reliance != 0 && item?.Reliance != null ? item?.Reliance :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Reliance}
                                id="reliance"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Future ? item?.Future : 'N/A'}</td> : companyValue == 'Future' ? <td>{item?.Future != 0 && item?.Future != null ? item?.Future :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Future}
                                id="future"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Royal ? item?.Royal : "N/A"}</td> : companyValue == 'Royal' ? <td>{item?.Royal != 0 && item?.Royal != null ? item?.Royal :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Royal}
                                id="royal"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Bajaj ? item?.Bajaj : "N/A"}</td> : companyValue == 'Bajaj' ? <td>{item?.Bajaj != 0 && item?.Bajaj != null ? item?.Bajaj :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Bajaj}
                                id="Bajaj"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Liberty ? item?.Liberty : "N/A"}</td> : companyValue == 'Liberty' ? <td>{item?.Liberty != 0 && item?.Liberty != null ? item?.Liberty :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Liberty}
                                id="Liberty"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Zuno ? item?.Zuno : "N/A"}</td> : companyValue == 'Zuno' ? <td>{item?.Zuno != 0 && item?.Zuno != null ? item?.Zuno :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Zuno}
                                id="Zuno"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Acko ? item?.Acko : "N/A"}</td> : companyValue == 'Acko' ? <td>{item?.Acko != 0 && item?.Acko != null ? item?.Acko :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Acko}
                                id="Acko"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Chola ? item?.Chola : "N/A"}</td> : companyValue == 'Chola' ? <td>{item?.Chola != 0 && item?.Chola != null ? item?.Chola :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Chola}
                                id="Chola"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.ICICI ? item?.ICICI : "N/A"}</td> : companyValue == 'ICICI' ? <td>{item?.ICICI != 0 && item?.ICICI != null ? item?.ICICI :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.ICICI}
                                id="ICICI"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.IFFCO ? item?.IFFCO : "N/A"}</td> : companyValue == 'IFFCO' ? <td>{item?.IFFCO != 0 && item?.IFFCO != null ? item?.IFFCO :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.IFFCO}
                                id="IFFCO"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Magma ? item?.Magma : "N/A"}</td> : companyValue == 'Magma' ? <td>{item?.Magma != 0 && item?.Magma != null ? item?.Magma :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Magma}
                                id="Magma"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.National ? item?.National : "N/A"}</td> : companyValue == 'National' ? <td>{item?.National != 0 && item?.National != null ? item?.National :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.National}
                                id="National"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Raheja ? item?.Raheja : "N/A"}</td> : companyValue == 'Raheja' ? <td>{item?.Raheja != 0 && item?.Raheja != null ? item?.Raheja :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Raheja}
                                id="Raheja"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.SBI ? item?.SBI : "N/A"}</td> : companyValue == 'SBI' ? <td>{item?.SBI != 0 && item?.SBI != null ? item?.SBI :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.SBI}
                                id="SBI"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Tata ? item?.Tata : "N/A"}</td> : companyValue == 'Tata' ? <td>{item?.Tata != 0 && item?.Tata != null ? item?.Tata :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Tata}
                                id="Tata"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.NewIndia ? item?.NewIndia : "N/A"}</td> : companyValue == 'NewIndia' ? <td>{item?.NewIndia != 0 && item?.NewIndia != null ? item?.NewIndia :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.NewIndia}
                                id="NewIndia"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Oriental ? item?.Oriental : "N/A"}</td> : companyValue == 'Oriental' ? <td>{item?.Oriental != 0 && item?.Oriental != null ? item?.Oriental :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Oriental}
                                id="Oriental"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.United ? item?.United : "N/A"}</td> : companyValue == 'United' ? <td>{item?.United != 0 && item?.United != null ? item?.United :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.United}
                                id="United"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Universal ? item?.Universal : "N/A"}</td> : companyValue == 'Universal' ? <td>{item?.Universal != 0 && item?.Universal != null ? item?.Universal :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Universal}
                                id="Universal"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}
                            {companyValue == '' ? <td>{item?.Navi ? item?.Navi : "N/A"}</td> : companyValue == 'Navi' ? <td>{item?.Navi != 0 && item?.Navi != null ? item?.Navi :
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={item?.Navi}
                                id="Navi"
                                onChange={(e) => {
                                  let data = mmvUpdate?.filter(e => e.id != item?.id)
                                  let data2 = {
                                    id: item?.id,
                                    "code": e.target.value
                                  }
                                  setMMvUpdate([...data, data2])
                                }
                                }
                                placeholder=" Enter code"
                              />
                            }</td> : <></>}


                            <td><Link to={'/editmmv'} state={{ mmv: item }} ><i class="fas fa-edit"></i></Link></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* 
                  {filterData.length > 0 ?
                    <div className='d-flex justify-content-between'>
                      {filterPages > 1 &&
                        <div className="backbtn mt-5 text-start">
                          <span
                            onClick={previousFilterPage}
                            className="btn btn-primary w-sm waves-effect waves-light"
                          >
                            Previous
                          </span>
                        </div>
                      }


                      <div className="backbtn mt-5 text-end">
                        <span
                          onClick={nextFilterPage}
                          className="btn btn-primary w-sm waves-effect waves-light"
                        >
                          Next
                        </span>
                      </div>

                      <div className="backbtn mt-5 text-end">
                        <span
                          onClick={handleUpdateMMv}
                          className="btn btn-primary w-sm waves-effect waves-light"
                        >
                          {loading == true ? <span className="spinner-border spinner-border-sm"></span> : " Update Mmv"}
                        </span>
                      </div>
                    </div>

                    : */}

                  <div className='d-flex justify-content-between'>
                    {pages > 1 &&
                      <div className="backbtn mt-5 text-start">
                        <span
                          onClick={previousPage}
                          className="btn btn-primary w-sm waves-effect waves-light"
                        >
                          Previous
                        </span>
                      </div>
                    }


                    <div className="backbtn mt-5 text-end">
                      <span
                        onClick={nextPage}
                        className="btn btn-primary w-sm waves-effect waves-light"
                      >
                        Next
                      </span>
                    </div>

                    <div className="backbtn mt-5 text-end">
                      <span
                        onClick={handleUpdateMMv}
                        className="btn btn-primary w-sm waves-effect waves-light"
                      >
                        {loading == true ? <span className="spinner-border spinner-border-sm"></span> : " Update Mmv"}
                      </span>
                    </div>
                  </div>
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        }
      </div>


      <Modal isOpen={modal} centered toggle={toggle} className={modal}>
        <ModalHeader toggle={toggle}>
          Update {editCompanyName} Mmv Code
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12 col-12 ps-lg-0">
            <div className="position-relative">
              <div className="form-floating inother">
                <input
                  type="text"
                  className="form-control"
                  id="Cusname"
                  onChange={(e) => setEditCompanyCode(e.target.value)}
                  placeholder=" Enter Customer Pincode"
                />
                <label htmlFor="Cusname">
                  {editCompanyName} Code
                  <span className="text-danger">*</span>
                </label>
              </div>
            </div>

            <div className="text-end mt-5">
              <button className="btn btn-primary " onClick={submitCode}>
                {loading == true ? <span className="spinner-border spinner-border-sm"></span> : "Submit"}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>


    </div>
  )
}

export default AllMmv