import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
  useEffect(() => {
    const script = document.createElement("script");
    const script1 = document.createElement("script");

    script.src = `./assets/js/metimenu.js`;
    script.async = true;
    script1.src = `./assets/js/app.js`;
    script1.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script1);


  }, []);
  return (
    <>
      {/*----- Left side sidebar start -----*/}
      <div className="vertical-menu">
        {/*----- Website logo for lg screen -----*/}
        <div className="navbar-brand-box">
          <Link to={'/dashboard'} className="logo logo-dark d-block">
            <span className="logo-sm">
              <img
                src="assets/img/smalllogo.png"
                alt=""
                className="w-100"
              />
            </span>
            <span className="logo-lg">
              <img
                src="assets/img/expertlogoyellow.png"
                alt=""
                className=" w-100 pe-5"
              />
            </span>
          </Link>
        </div>
        {/*----- Sidebar toggle button when siderbar is shown -----*/}
        <button
          type="button"
          className="btn btn-sm p-0 font-size-16 header-item waves-effect vertical-menu-btn text-dark"
        >
          <i className="fa-solid fa-bars text-dark" />
        </button>
        <div data-simplebar="" className="sidebar-menu-scroll">
          {/*--- Sidemenu ----*/}
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/*----- bar menue list heading -----*/}
              <li className="menu-title d-none">Menu</li>
              <li>
                <Link to={'/dashboard'}>
                  <i className="fa-solid fa-house" />
                  {/*----- Notification count of the menu -----*/}
                  <span className="badge rounded-pill bg-primary float-end d-none">
                    01
                  </span>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/allmmv">
                  <i className="fa-solid fa-file-pen" />
                  {/*----- Notification count of the menu -----*/}
                  <span className="badge rounded-pill bg-primary float-end d-none">
                    01
                  </span>
                  <span>All Entries</span>
                </Link>
              </li>
              <li>
                <Link to={'/addmmv'}>
                  <i className="fa-solid fa-file-circle-plus" />
                  {/*----- Notification count of the menu -----*/}
                  <span className="badge rounded-pill bg-primary float-end d-none">
                    01
                  </span>
                  <span>Add new entry</span>
                </Link>
              </li>
              <li className="d-none">
                <a href="javascript: void(0);" className="has-arrow waves-effect">
                  <i className="fa-solid fa-user" />
                  <span>Clients</span>
                </a>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <a href="clientreq.html">Client Requests</a>
                  </li>
                  <li>
                    <a href="addentry.html">Add Client</a>
                  </li>
                  <li>
                    <a href="allentry.html">All Clients</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
        </div>
      </div>
      {/*----- Left side sidebar End -----*/}
    </>

  )
}

export default SideBar