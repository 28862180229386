import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./IsAuthenticate";

const CheckAuth = () => {
  // const type = useSelector((state) => state?.root?.userDetails.type);

  return !isAuthenticated() ? <Outlet /> : <Navigate to={`/dashboard`} />

}

export default CheckAuth