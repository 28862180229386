import { toast } from 'material-react-toastify';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostDataWithToken } from '../../api/apiHelper';
import Header from '../common/Header';
import SideBar from '../common/SideBar';

const EditMmv = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const mmvData = location.state.mmv;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    reset({
      digit_vehicle_code: mmvData?.Vehicle_Code,
      make: mmvData?.Make,
      model: mmvData?.Model,
      variant: mmvData?.Variant,
      body_type: mmvData?.Body_Type,
      seating_capacity: mmvData?.Seating_Capacity,
      power: mmvData?.Power,
      cubic_capacity: mmvData?.Cubic_Capacity,
      grosss_vehicle_weight: mmvData?.Grosss_Vehicle_Weight,
      fuel_type: mmvData?.Fuel_Type,
      no_of_wheels: mmvData?.No_Of_Wheels,
      abs: mmvData?.Abs,
      air_bags: mmvData?.Air_Bags,
      length: mmvData?.Length,
      ex_showroom_price: mmvData?.Ex_Showroom_Price,
      price_year: mmvData?.Price_Year,
      production: mmvData?.Production,
      manufacturing: mmvData?.Manufacturing,
      vehicle_type: mmvData?.Vehicle_Type,
      HDFC: mmvData?.HDFC,
      shriram: mmvData?.Shriram,
      kotak: mmvData?.Kotak,
      reliance: mmvData?.Reliance,
      future: mmvData?.Future,
      royal: mmvData?.Royal,
      bajaj: mmvData?.Bajaj,
      liberty: mmvData?.Liberty,
      zuno: mmvData?.Zuno,
      Acko: mmvData?.Acko,
      Chola: mmvData?.Chola,
      ICICI: mmvData?.ICICI,
      IFFCO: mmvData?.IFFCO,
      Magma: mmvData?.Magma,
      National: mmvData?.National,
      Raheja: mmvData?.Raheja,
      SBI: mmvData?.SBI,
      Tata: mmvData?.Tata,
      NewIndia: mmvData?.NewIndia,
      Oriental: mmvData?.Oriental,
      United: mmvData?.United,
      Universal: mmvData?.Universal,
      Navi: mmvData?.Navi
    })
  }, [])

  const submitData = (data) => {
    console.log("hghg", data)
    setLoading(true);
    // const formData = new FormData();
    // for (let key in data) {
    //   formData.append(key, data[key]);
    // }

    PostDataWithToken(`mmv/edit/${mmvData?.id}`, data).then((response) => {
      if (response.status == true) {
        toast.success(response.message);
        navigate('/allmmv')
      } else {
        toast.error(response.data.message);
        setLoading(false)
      }
    })

  }

  return (
    <div id="layout-wrapper">
      <Header />
      <SideBar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/*----- Page title and breadcrumb -----*/}
            <div className="row">
              <div className="col-12  d-flex justify-content-between align-items-center">
                <div className="page-title-box">
                  <h4 className="mb-0">Add Entries</h4>
                  <div className="page-title-right mt-2">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <i className="fa-solid fa-house " />
                        <a href="dashboard.html">Dashboard</a>
                      </li>
                      <li className="breadcrumb-item">Add New Entry</li>
                    </ol>
                  </div>
                </div>
                <div className="backbtn">
                  <a
                    href="javascript:void(0);"
                    onClick={() => navigate(-1)}
                    className="btn btn-primary w-sm waves-effect waves-light"
                  >
                    Back
                  </a>
                </div>
              </div>
            </div>
            {/*----- Add Client Form -----*/}
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(submitData)}>
                      <div className="row mx-0 px-0">
                        <div className="col-lg-4 col-12 ps-lg-0">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="vcname">
                              Digit Vehicle Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("digit_vehicle_code", {
                                // required: "Field is required",
                              })}
                              id="vcname"
                              placeholder="Enter vehicle code"
                            />

                            <p className="mb-0 f-error">{errors?.digit_vehicle_code?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Make">
                              Make
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("make", {
                                required: "Field is required",
                              })}
                              id="Make"
                              placeholder="Enter Make"
                            />
                            <p className="mb-0 f-error">{errors?.make?.message}</p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Model">
                              Model
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("model", {
                                required: "Field is required",
                              })}
                              id="Model"
                              placeholder="Enter  Model"
                            />
                            <p className="mb-0 f-error">{errors?.model?.message}</p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Variant">
                              Variant
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Variant"
                              {...register("variant", {
                                required: "Field is required",
                              })}
                              placeholder="Enter  Variant"
                            />
                            <p className="mb-0 f-error">{errors?.variant?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="BodyType">
                              Body Type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("body_type", {
                                // required: "Field is required",
                              })}
                              id="BodyType"
                              placeholder="Enter Body Type"
                            />
                            <p className="mb-0 f-error">{errors?.body_type?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="SeatingCapacity">
                              Seating Capacity
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("seating_capacity", {
                                // required: "Field is required",
                              })}
                              id="SeatingCapacity"
                              placeholder="Enter Seating Capacity"
                            />
                            <p className="mb-0 f-error">{errors?.seating_capacity?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Power">
                              Power
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("power", {
                                // required: "Field is required",
                              })}
                              id="Power"
                              placeholder="Enter Power"
                            />
                            <p className="mb-0 f-error">{errors?.power?.message}</p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="CubicCapacity">
                              Cubic Capacity
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("cubic_capacity", {
                                // required: "Field is required",
                              })}
                              id="CubicCapacity"
                              placeholder="Enter Cubic Capacity"
                            />
                            <p className="mb-0 f-error">{errors?.cubic_capacity?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="GrosssVehicleWeight">
                              Grosss Vehicle Weight
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("grosss_vehicle_weight", {
                                // required: "Field is required",
                              })}
                              id="GrosssVehicleWeight"
                              placeholder="Enter Grosss Vehicle Weight"
                            />
                            <p className="mb-0 f-error">{errors?.grosss_vehicle_weight?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="FuelType">
                              Fuel Type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("fuel_type", {
                                required: "Field is required",
                              })}
                              id="FuelType"
                              placeholder="Enter Fuel Type"
                            />
                            <p className="mb-0 f-error">{errors?.fuel_type?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="NoOfWheels">
                              No Of Wheels
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no_of_wheels", {
                                // required: "Field is required",
                              })}
                              id="NoOfWheels"
                              placeholder="Enter No Of Wheels"
                            />
                            <p className="mb-0 f-error">{errors?.no_of_wheels?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Abs">
                              Abs
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("abs", {
                                // required: "Field is required",
                              })}
                              id="Abs"
                              placeholder="Enter Abs"
                            />

                            <p className="mb-0 f-error">{errors?.abs?.message}</p>

                          </div>
                        </div>


                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Air_Bags">
                              Air Bags
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("air_bags", {
                                // required: "Field is required",
                              })}
                              id="Air_Bags"
                              placeholder="Enter Air Bags"
                            />
                            <p className="mb-0 f-error">{errors?.air_bags?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Length">
                              Length
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("length", {
                                // required: "Field is required",
                              })}
                              id="Length"
                              placeholder="Enter Length"
                            />
                            <p className="mb-0 f-error">{errors?.length?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="ExShowroomPrice">
                              Ex Showroom Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("ex_showroom_price", {
                                // required: "Field is required",
                              })}
                              id="ExShowroomPrice"
                              placeholder="Enter Ex Showroom Price"
                            />
                            <p className="mb-0 f-error">{errors?.ex_showroom_price?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="PriceYear">
                              Price Year
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("price_year", {
                                // required: "Field is required",
                              })}
                              id="PriceYear"
                              placeholder="Enter Price Year"
                            />
                            <p className="mb-0 f-error">{errors?.price_year?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Production">
                              Production
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("production", {
                                // required: "Field is required",
                              })}
                              id="Production"
                              placeholder="Enter Production"
                            />
                            <p className="mb-0 f-error">{errors?.production?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Manufacturing">
                              Manufacturing
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("manufacturing", {
                                // required: "Field is required",
                              })}
                              id="Manufacturing"
                              placeholder="Enter Manufacturing"
                            />
                            <p className="mb-0 f-error">{errors?.manufacturing?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="VehicleType">
                              Vehicle Type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("vehicle_type", {
                                // required: "Field is required",
                              })}
                              id="VehicleType"
                              placeholder="Enter Vehicle Type"
                            />
                            <p className="mb-0 f-error">{errors?.vehicle_type?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="HDFC">
                              HDFC
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("HDFC", {
                                // required: "Field is required",
                              })}
                              id="HDFC"
                              placeholder="Enter HDFC"
                            />
                            <p className="mb-0 f-error">{errors?.HDFC?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Shriram">
                              Shriram
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("shriram", {
                                // required: "Field is required",
                              })}
                              id="Shriram"
                              placeholder="Enter Shriram"
                            />
                            <p className="mb-0 f-error">{errors?.shriram?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Kotak">
                              Kotak
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("kotak", {
                                // required: "Field is required",
                              })}
                              id="Kotak"
                              placeholder="Enter Kotak"
                            />
                            <p className="mb-0 f-error">{errors?.kotak?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Reliance">
                              Reliance
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("reliance", {
                                // required: "Field is required",
                              })}
                              id="Reliance"
                              placeholder="Enter Reliance"
                            />
                            <p className="mb-0 f-error">{errors?.reliance?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Future">
                              Future
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("future", {
                                // required: "Field is required",
                              })}
                              id="Future"
                              placeholder="Enter Future"
                            />
                            <p className="mb-0 f-error">{errors?.future?.message}</p>

                          </div>
                        </div>

                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Royal">
                              Royal
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("royal", {
                                // required: "Field is required",
                              })}
                              id="Future"
                              placeholder="Enter Royal"
                            />
                            <p className="mb-0 f-error">{errors?.royal?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="bajaj">
                              Bajaj
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("bajaj", {
                                // required: "Field is required",
                              })}
                              id="bajaj"
                              placeholder="Enter bajaj"
                            />
                            <p className="mb-0 f-error">{errors?.bajaj?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Zuno">
                              Zuno
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("zuno", {
                                // required: "Field is required",
                              })}
                              id="Zuno"
                              placeholder="Enter Zuno"
                            />
                            <p className="mb-0 f-error">{errors?.zuno?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Liberty">
                              Liberty
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("liberty", {
                                // required: "Field is required",
                              })}
                              id="Liberty"
                              placeholder="Enter Liberty"
                            />
                            <p className="mb-0 f-error">{errors?.liberty?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Acko">
                              Acko
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Acko", {
                                // required: "Field is required",
                              })}
                              id="Acko"
                              placeholder="Enter Acko"
                            />
                            <p className="mb-0 f-error">{errors?.Acko?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Chola">
                              Chola
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Chola", {
                                // required: "Field is required",
                              })}
                              id="Chola"
                              placeholder="Enter Chola"
                            />
                            <p className="mb-0 f-error">{errors?.Chola?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="ICICI">
                              ICICI
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("ICICI", {
                                // required: "Field is required",
                              })}
                              id="ICICI"
                              placeholder="Enter ICICI"
                            />
                            <p className="mb-0 f-error">{errors?.ICICI?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="IFFCO">
                              IFFCO
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("IFFCO", {
                                // required: "Field is required",
                              })}
                              id="IFFCO"
                              placeholder="Enter IFFCO"
                            />
                            <p className="mb-0 f-error">{errors?.IFFCO?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Magma">
                              Magma
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Magma", {
                                // required: "Field is required",
                              })}
                              id="Magma"
                              placeholder="Enter Magma"
                            />
                            <p className="mb-0 f-error">{errors?.Magma?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="National">
                              National
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("National", {
                                // required: "Field is required",
                              })}
                              id="National"
                              placeholder="Enter National"
                            />
                            <p className="mb-0 f-error">{errors?.National?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Raheja">
                              Raheja
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Raheja", {
                                // required: "Field is required",
                              })}
                              id="Raheja"
                              placeholder="Enter Raheja"
                            />
                            <p className="mb-0 f-error">{errors?.Raheja?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="SBI">
                              SBI
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("SBI", {
                                // required: "Field is required",
                              })}
                              id="SBI"
                              placeholder="Enter SBI"
                            />
                            <p className="mb-0 f-error">{errors?.SBI?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Tata">
                              Tata
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Tata", {
                                // required: "Field is required",
                              })}
                              id="Tata"
                              placeholder="Enter Tata"
                            />
                            <p className="mb-0 f-error">{errors?.Tata?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="NewIndia">
                              NewIndia
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("NewIndia", {
                                // required: "Field is required",
                              })}
                              id="NewIndia"
                              placeholder="Enter NewIndia"
                            />
                            <p className="mb-0 f-error">{errors?.NewIndia?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Oriental">
                              Oriental
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Oriental", {
                                // required: "Field is required",
                              })}
                              id="Oriental"
                              placeholder="Enter Oriental"
                            />
                            <p className="mb-0 f-error">{errors?.Oriental?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="United">
                              United
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("United", {
                                // required: "Field is required",
                              })}
                              id="United"
                              placeholder="Enter United"
                            />
                            <p className="mb-0 f-error">{errors?.United?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Universal">
                              Universal
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Universal", {
                                // required: "Field is required",
                              })}
                              id="Universal"
                              placeholder="Enter Universal"
                            />
                            <p className="mb-0 f-error">{errors?.Universal?.message}</p>

                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="Navi">
                              Navi
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("Navi", {
                                // required: "Field is required",
                              })}
                              id="Navi"
                              placeholder="Enter Navi"
                            />
                            <p className="mb-0 f-error">{errors?.Navi?.message}</p>

                          </div>
                        </div>

                      </div>
                      <div className="mt-3 text-end">
                        <button
                          type='submit'
                          className="btn btn-primary w-sm waves-effect waves-light"
                        >
                          {loading == true ? <span className="spinner-border spinner-border-sm"></span> : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default EditMmv