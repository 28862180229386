import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { GetData } from '../../api/apiHelper'
import Header from '../common/Header'
import SideBar from '../common/SideBar'

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState('')

  useEffect(() => {
    GetData('mmv/dashboard', '').then((response) => {
      if (response.status == true) {
        setDashboardData(response.data)
      }
    })


    GetData('sales/get-enquiry/1', '').then((response) => {
      if (response.status == true) {
        console.log(response.data)
      }
    })
  }, [])

  console.log(dashboardData)

  return (

    <div id="layout-wrapper">
      <Header />
      <SideBar />
      {/*----- Main content area start -----*/}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/*----- Page title and breadcrumb -----*/}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Data Entry Dashboard</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="index.html">Data entry Login</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/*----- Dashboard informative card -----*/}
            <div className="row">
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'id' }}>
                      <div className="float-end mt-2">
                        <div
                          id="total-revenue-chart"
                          data-colors='["--bs-primary"]'
                        />
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          All Mmv <span > {dashboardData?.all ? dashboardData?.all : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Vehicle_Code' }}>
                      <div className="float-end mt-2">
                        <div id="orders-chart" data-colors='["--bs-success"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Digit Mmv
                          <span > {dashboardData?.digit ? dashboardData?.digit : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Future' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Future Mmv
                          <span > {dashboardData?.future ? dashboardData?.future : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'HDFC' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Hdfc Mmv
                          <span > {dashboardData?.hdfc ? dashboardData?.hdfc : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Kotak' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Kotak Mmv
                          <span > {dashboardData?.kotak ? dashboardData?.kotak : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Reliance' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Reliance Mmv
                          <span > {dashboardData?.reliance ? dashboardData?.reliance : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Royal' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Royal Mmv
                          <span > {dashboardData?.royal ? dashboardData?.royal : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <Link to={'/allmmv'} state={{ mmvtype: 'Shriram' }}>
                      <div className="float-end mt-2">
                        <div id="Entries-chart" data-colors='["--bs-primary"]'>
                          {" "}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          Shriram Mmv
                          <span > {dashboardData?.shriram ? dashboardData?.shriram : 0}</span>
                        </h4>
                        {/* <p className="text-muted mb-0">Subheading</p> */}
                      </div>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-success me-1">
                          <i className="fa-solid fa-arrow-up me-1" />
                          2.65%
                        </span>
                        since last week
                      </p>
                      <p className="text-muted mt-3 mb-0">
                        <span className="text-danger me-1">
                          <i className="fa-solid fa-arrow-down me-1" />
                          0.82%
                        </span>
                        since last week
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
            {/* <div className="row">
              <div className="col-xl-12 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="float-end">
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle text-reset"
                          href="#"
                          id="dropdownMenuButton5"
                          data-bs-toggle="dropdown"
                        >
                          <span className="fw-semibold">Sort By:</span>
                          <span className="text-muted">
                            Yearly
                            <i className="fa-solid fa-chevron-down ms-1" />
                          </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a className="dropdown-item" href="#">
                            Monthly
                          </a>
                          <a className="dropdown-item" href="#">
                            Yearly
                          </a>
                          <a className="dropdown-item" href="#">
                            Weekly
                          </a>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title mb-4">
                      Expert Cover Data Entry System Analytics
                    </h4>
                    <div className="mt-1">
                      <ul className="list-inline main-chart mb-0">
                        <li className="list-inline-item chart-border-left me-0 border-0">
                          <h3 className="text-primary">
                            Rupee
                            <span >2,371.000</span>
                            <span className="text-muted d-inline-block font-size-15 ms-3">
                              Income
                            </span>
                          </h3>
                        </li>
                        <li className="list-inline-item chart-border-left me-0">
                          <h3>
                            <span >258</span>
                            <span className="text-muted d-inline-block font-size-15 ms-3">
                              Entries
                            </span>
                          </h3>
                        </li>
                        <li className="list-inline-item chart-border-left me-0">
                          <h3>
                            <span >3.6</span>%
                            <span className="text-muted d-inline-block font-size-15 ms-3">
                              Total Revenue
                            </span>
                          </h3>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-3">
                      <div
                        id="sales-analytics-chart"
                        data-colors='["--bs-primary", "#dfe2e6", "--bs-warning"]'
                        className="apex-charts"
                        dir="ltr"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/*----- Main content area End -----*/}
    </div>
  )
}

export default Dashboard