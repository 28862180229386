import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PostData } from '../../api/apiHelper';

import cookie from "react-cookies";
import { toast } from 'material-react-toastify';


const Login = () => {

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();

  const handleLogin = (data) => {
    setLoading(true)
    let Data = {
      ...data,
      fcm_token: "sadfdfgfdgh",
    };

    PostData('auth/login', Data).then((response) => {
      if (response.status == true) {
        toast.success(response.message);
        cookie.save("userDetails", response.data);
        cookie.save("mmvtoken", response.data.access_token);
        // dispatch(saveUserDetails(response.data));
        navigate(`/dashboard`);
      } else {
        toast.error(response.data.message)
      }
    })
  }

  return (
    <div className="authentication-bg">
      <div className="account-pages position-relative py-5 h-100">
        {/*----- Container start -----*/}
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-4 offset-7">
              {/*----- Website logo ------*/}
              <a href="index.html" className="mb-5 d-block auth-logo">
                <img
                  src="./assets/img/expertlogowhite.png"
                  alt=""
                  className="logo logo-dark w-100 h-100"
                />
              </a>
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h3 className="text-primary mb-0">Welcome !</h3>
                    <p className="text-muted fs-4 text-decoration-underline">
                      Sign in to continue to mmv admin.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={handleSubmit(handleLogin)}>
                      <div className="mb-3 pb-2 position-relative">
                        <label className="form-label" htmlFor="username">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Enter username"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <p className="f-error m-0">
                          {errors?.email?.message}
                        </p>
                      </div>
                      <div className="pb-2 position-relative">
                        <label className="form-label" htmlFor="userpassword">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="userpassword"
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters",
                            },
                          })}
                          placeholder="Enter password"
                        />
                        <p className="f-error m-0">
                          {errors?.password?.message}
                        </p>
                      </div>
                      <div className="mt-4">
                        <button
                          type='submit'
                          className="btn btn-primary fw-bold w-sm waves-effect waves-light"
                        >
                          {loading == true ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "LogIn"
                          )}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="fw-bold mb-0">
                          ©<span className="text-primary">E</span>xpert
                          <span className="text-primary">C</span>over All Rights
                          Reserved
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/*----- Container End -----*/}
      </div>
    </div>

  )
}

export default Login