import React, { useEffect } from 'react'
import cookie from "react-cookies";
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()

  const logout = () => {
    cookie.remove("userDetails");
    cookie.remove("mmvtoken")
    navigate('/')
  }

  return (
    <>
      {/*----- Header start -----*/}
      <header id="page-topbar">
        <div className="navbar-header">
          {/*----- This div contains element of sm screens basically -----*/}
          <div className="d-flex">
            {/*----- Website Logo container for sm screen -----*/}
            <div className="navbar-brand-box">
              <Link to={'/dashboard'} className="logo logo-dark">
                {/*----- website logo for sm condition of sidebar -----*/}
                <span className="logo-sm">
                  <img src="./assets/img/expertlogowhite.png" alt="" height={22} />
                </span>
                {/*----- website logo for lg condition of sidebar -----*/}
                <span className="logo-lg">
                  <img src="./assets/img/expertlogowhite.png" alt="" height={20} />
                </span>
              </Link>
            </div>
            {/*----- toggle button for menu bar in sm screens -----*/}
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn text-dark"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          {/*----- This div contains element of both lg/sm screens basically -----*/}
          <div className="d-flex">
            {/*----- Notification toggler -----*/}
            {/* <div className="dropdown d-inline-block me-lg-3">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-bell fs-4 text-dark" />
                <span className="badge bg-danger rounded-pill">3</span>
              </button>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-0 font-size-16"> Notifications </h5>
                    </div>
                  </div>
                </div>
                <div data-simplebar="" style={{ maxHeight: 230 }}>
                  <a
                    href="javascript:void(0);"
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="fa-regular fa-comments" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">One Case Enquiry is placed.</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">XYZ user placed the enquiry</p>
                          <p className="mb-0">
                            <i className="fa-regular fa-clock" /> 3 min ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="fa-regular fa-comments" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">One Case Enquiry is placed.</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">XYZ user placed the enquiry</p>
                          <p className="mb-0">
                            <i className="fa-regular fa-clock" /> 3 min ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="fa-regular fa-comments" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">One Case Enquiry is placed.</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">XYZ user placed the enquiry</p>
                          <p className="mb-0">
                            <i className="fa-regular fa-clock" /> 3 min ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="fa-regular fa-comments" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">One Case Enquiry is placed.</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">XYZ user placed the enquiry</p>
                          <p className="mb-0">
                            <i className="fa-regular fa-clock" /> 3 min ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="p-2 border-top">
                  <div className="d-grid">
                    <a
                      className="btn btn-sm btn-link font-size-14 text-center"
                      href="#"
                    >
                      <i className="uil-arrow-circle-right me-1" />
                      View More..
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/*----- user profile toggle button with dropdown -----*/}
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="assets/img/usericon.png"
                  alt=""
                />
              </button>
              {/*----- Dropdown option -----*/}
              <div className="dropdown-menu dropdown-menu-end">
                {/* <a className="dropdown-item" href="profile.html">
                  <i className="fa-regular fa-circle-user font-size-18 align-middle text-muted me-1" />
                  <span className="align-middle">View Profile</span>
                </a> */}
                <a className="dropdown-item" href="javascript:void(0);" onClick={logout}>
                  <i className="fa-solid fa-arrow-right-from-bracket font-size-18 align-middle me-1 text-muted" />
                  <span className="align-middle">Sign out</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*----- Header End -----*/}
    </>

  )
}

export default Header