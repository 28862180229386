import React from 'react'
import './loader.css'

const Loader = () => {
  return (
    <>
      <div className="loader-set">
        <div className="loadingio-spinner-double-ring-420dmf2492k">
          <div className="ldio-herkojlg1d7">
            <div />
            <div />
            <div>
              <div />
            </div>
            <div>
              <div />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Loader